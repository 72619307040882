<template>
  <div class="stepper-bar">
      <div
          :class="getStepStyle(step)"
          v-for="(step, stepIndex) in steps"
          :key="stepIndex"
      >
          <div class="progress">
              <div class="progress-left"></div>
              <div class="progress-right"></div>
          </div>
          <div class="item-content">
              <div class="item-bullet">
                  <img class="item-icon" :src="step.icon" border="0" />
              </div>
              <div
                  class="item-label"
              >{{ step.legend }}</div>
          </div>
      </div>
  </div>
</template>

<script>
import ServiceStatus from '@/constants/service-status'
import ProductStatus from '@/constants/product-status'

export default {
    props: {
        type: {
            type: String
        },
        status: {
            type: String
        },
        receivedDateText: {
            type: String,
            default: null
        }
    },
    computed: {
        steps () {
            var legend = '-'
            var legendClass = 'legend-text-ok'
            var trackingLink = null
            switch (this.type) {
                case 'product':
                    switch (this.status) {
                        case ProductStatus.WAITING_ACCEPTANCE:
                        case ProductStatus.STAGING:
                            return [
                                {
                                    id: 1,
                                    legend: `En attente d'acceptation`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `En attente d'envoi`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: null
                                },
                                {
                                    id: 3,
                                    legend: `En attente de livraison`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: null
                                }
                            ]
                        case ProductStatus.REFUSED:
                            return [
                                {
                                    id: 1,
                                    legend: `x Refusée`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'failed'
                                },
                                {
                                    id: 2,
                                    legend: `-`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: null
                                },
                                {
                                    id: 3,
                                    legend: `-`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: null
                                }
                            ]
                        case ProductStatus.SHIPPING:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `En cours de préparation`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 3,
                                    legend: `En attente de livraison`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: null
                                }
                            ]
                        case ProductStatus.CANCELED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'mid-checked'
                                },
                                {
                                    id: 2,
                                    legend: `x Annulée`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'mid-failed'
                                },
                                {
                                    id: 3,
                                    legend: `-`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: null
                                }
                            ]
                        case ProductStatus.SHIPPED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `✓ Envoyée`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 3,
                                    legend: `En cours de livraison`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: 'checked'
                                }
                            ]
                        case ProductStatus.CLOSED:
                        case ProductStatus.RECEIVED:
                        case ProductStatus.INCIDENT_OPEN:
                        case ProductStatus.WAITING_REFUND:
                        case ProductStatus.WAITING_REFUND_PAYMENT:
                        case ProductStatus.REFUNDED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/cosmetics.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `✓ Envoyée`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 3,
                                    legend: `✓ Livrée le ${this.receivedDateText}`,
                                    icon: require('@/assets/images/ehpad/delivery.svg'),
                                    state: 'checked'
                                }
                            ]
                    }
                    break
                case 'service':
                    switch (this.status) {
                        case ServiceStatus.WAITING_ACCEPTANCE:
                        case ServiceStatus.WAITING_SCORING:
                        case ServiceStatus.SCORING_OK:
                        case ServiceStatus.SCORING_KO:
                            return [
                                {
                                    id: 1,
                                    legend: `En attente d'acceptation`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `En attente de réalisation`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: null
                                }
                            ]
                        case ServiceStatus.ORDER_ACCEPTED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `En attente de réalisation`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: null
                                }
                            ]
                        case ServiceStatus.ORDER_REFUSED:
                            return [
                                {
                                    id: 1,
                                    legend: `x Refusée`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'failed'
                                },
                                {
                                    id: 2,
                                    legend: `-`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: null
                                }
                            ]
                        case ServiceStatus.ORDER_PENDING:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `En attente de réalisation`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'checked'
                                }
                            ]
                        case ServiceStatus.ORDER_CLOSED:
                        case ServiceStatus.ORDER_CONSUMED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'checked'
                                },
                                {
                                    id: 2,
                                    legend: `✓ Réalisée`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'checked'
                                }
                            ]
                        case ServiceStatus.ORDER_EXPIRED:
                        case ServiceStatus.ORDER_CANCELLED:
                            return [
                                {
                                    id: 1,
                                    legend: `✓ Acceptée`,
                                    icon: require('@/assets/images/ehpad/calendar.svg'),
                                    state: 'mid-checked'
                                },
                                {
                                    id: 2,
                                    legend: `x Annulée`,
                                    icon: require('@/assets/images/ehpad/open-cardboard-box.svg'),
                                    state: 'mid-failed'
                                }
                            ]
                    }
                    break
            }
        }
    },
    methods: {
        getStepStyle (step) {
            var style = 'stepper-item'
            switch (step.state) {
                case 'checked':
                    style += ' item-checked'
                    break
                case 'mid-checked':
                    style += ' item-mid-checked'
                    break
                case 'failed':
                    style += ' item-failed'
                    break
                case 'mid-failed':
                    style += ' item-mid-failed'
                    break
            }
            return style
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.stepper-bar {
    display: flex;
    align-items: center;
    height: 140px;
    margin: 0px 0px 40px 0px;

    .stepper-item {
        position: relative;
        height: 100%;
        text-align: center;
        flex-grow: 1;

        .progress {
            position: absolute;
            left: 0px;
            top: 0px;
            display: flex;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            z-index: 2;

            .progress-left {
                width: 50%;
                height: 50%;
                border-bottom: 2px solid #BFBFBF;
            }
            .progress-right {
                width: 50%;
                height: 50%;
                border-bottom: 2px solid #BFBFBF;
            }
        }
        .item-content {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            z-index: 4;
            color: #BFBFBF;
            text-align: center;

            .item-bullet {
                display: inline-block;
                width: 70px;
                height: 70px;
                margin: 30px 0px 0px 0px;
                border-radius: 35px;
                background: #BFBFBF;
                text-align: center;

                .item-icon {
                    width: 40px;
                    margin: 12px 0px 0px 0px;
                }
            }
            .item-label {
                margin: 7px 0px 0px 0px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
    .stepper-item:first-child {

        .progress {

            .progress-left {
                border-color: transparent;
            }
        }
    }
    .stepper-item:last-child {

        .progress {

            .progress-right {
                border-color: transparent;
            }
        }
    }
    .item-checked {

        .item-content {
            color: rgba(var(--vs-primary), 1);

            .item-bullet {
                background: rgba(var(--vs-primary), 1);
            }
        }
        .progress {

            .progress-left {
                border-color: rgba(var(--vs-primary), 1);
            }
            .progress-right {
                border-color: rgba(var(--vs-primary), 1);
            }
        }
    }
    .item-mid-checked {

        .item-content {
            color: rgba(var(--vs-primary), 1);

            .item-bullet {
                background: rgba(var(--vs-primary), 1);
            }
        }
        .progress {

            .progress-left {
                border-color: rgba(var(--vs-primary), 1);
            }
        }
    }
    .item-failed {

        .item-content {
            color: #E03A3E;

            .item-bullet {
                background: #E03A3E;
            }
        }
        .progress {

            .progress-left {
                border-color: rgba(var(--vs-primary), 1);
            }
        }
    }
    .item-mid-failed {

        .item-content {
            color: #E03A3E;

            .item-bullet {
                background: #E03A3E;
            }
        }
    }
}
</style>
