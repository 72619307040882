<template>
    <div id="resident-order">

        <IncidentSidebar
            :title="incidentTitle"
            :active="incidentSidebarActive"
            :products="incidentProducts"
            :type="incidentActionType"
            @close="onIncidentSidebarClose"
            @confirm="onIncidentSidebarConfirm"
        />

        <ResidentHeader
            page-title="Commande"
            :displayResidentMenu="false"
        />
        <BackButton
            label="Retour aux commandes"
            @click="onBackBtnClick"
        />

        <div class="status-bar">
            <div
                class="status-label"
                :class="order.statusClass"
            >● {{ getStatusText(order) }}</div>
            <div class="vendor-label">{{ order.sellerName }}</div>
            <div class="status-blank"></div>
            <HBtn
                v-if="isIncidentBtnVisible()"
                class="incident-btn"
                style-type="secondary"
                :disabled="isIncidentBtnDisabled()"
                @click="onIncidentBtnClick"
                data-cy="incident-btn"
            >Déclarer un incident</HBtn>
            <HBtn
                v-if="order.shippingTrackingUrl"
                icon="TruckDeliveryIcon"
                class="tracking-btn"
                :href="order.shippingTrackingUrl"
                target="_blank"
                data-cy="tracking-btn"
            >Suivi du colis</HBtn>
            <HBtn
                v-if="order.hasInvoices && $acl.not.check('isEhpadEmployee') && $acl.not.check('isEhpadManager')"
                class="invoice-btn"
                style-type="secondary"
                icon="DownloadIcon"
                data-cy="invoice-btn"
                @click="onDownloadBtnClick(order)"
            >Facture</HBtn>
        </div>

        <OrderStatusViewer
            :type="order.type"
            :status="order.status"
            :receivedDateText="order.receivedDateText"
            :shippingTrackingUrl="order.shippingTrackingUrl"
        />

        <div class="order">

            <div class="order-header">
                <div class="number">Commande n° : {{ order.orderId }}</div>
                <div class="date">Date de création : {{ getDateLabel(order.date) }}</div>
            </div>

            <HRow class="order-content" :gap="4">
                <HCol class="order-list" :cols="12" :cols-lg="8">
                    <div class="order-list-header">Panier</div>
                    <div class="products">
                        <div
                            class="product-item"
                            v-for="(product, productIndex) in order.products"
                            :key="productIndex"
                        >
                            <hx-dropdown
                                class="incident-dropdown"
                                hx-custom-content
                                v-if="product.incident"
                            >
                                <div class="alert-btn">
                                    <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                </div>

                                <vs-dropdown-menu
                                    class="vx-navbar-dropdown incident-menu"
                                >
                                    <div class="incident-content">
                                        <div class="incident-header">
                                            <img class="alert-icon" src="@/assets/images/ehpad/alert.svg" />
                                            <div class="incident-title">Incident créé</div>
                                        </div>
                                        <div class="incident-reason">{{ product.incident.label }}</div>
                                        <div class="incident-text">
                                            {{ getProductIncidentLegend(product.incident) }}
                                        </div>
                                    </div>
                                </vs-dropdown-menu>
                            </hx-dropdown>
                            <img class="product-photo" :src="product.photo" />
                            <div class="informations">
                                <div class="product-title">{{ product.title }}</div>
                                <div class="product-description" v-html="product.description"></div>
                                <div class="product-legend">{{ product.legend }}</div>
                            </div>
                            <div class="status" :class="product.statusClass">{{ product.statusLabel }}</div>
                            <div class="quantity">
                                <div class="item-bullet">x{{ product.quantity }}</div>
                            </div>
                            <div class="price">{{ product.price }}€</div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="shipping">Frais de livraison : {{ order.shippingAmount }}€</div>
                        <div class="total">Total : {{ order.amount }}€</div>
                    </div>
                </HCol>
                <HCol :cols="12" :cols-lg="4">
                    <div class="information-list">
                        <div class="information-list-header">Informations générales</div>
                        <div class="informations">
                            <div class="information-item">
                                <div class="title">Adresse de livraison</div>
                                <div class="text">
                                    <img class="resident-icon" src="@/assets/images/ehpad/user.svg" />
                                    {{ getCustomerNameFromAddress(order.shippingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getInstitutionNameFromAddress(order.shippingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getText1FromAddress(order.shippingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getText2FromAddress(order.shippingAddress) }}
                                </div>
                            </div>
                            <div class="information-item">
                                <div class="title">Adresse de facturation</div>
                                <div class="text">
                                    <img class="resident-icon" src="@/assets/images/ehpad/user.svg" />
                                    {{ getCustomerNameFromAddress(order.billingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getInstitutionNameFromAddress(order.billingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getText1FromAddress(order.billingAddress) }}
                                </div>
                                <div class="text">
                                    {{ getText2FromAddress(order.billingAddress) }}
                                </div>
                            </div>
                            <div
                                v-if="order.type == 'product'"
                                class="information-item"
                            >
                                <div class="title">Mode de livraison</div>
                                <div class="text">{{ order.shippingTypeLabel }}</div>
                                <div class="text">
                                    {{ getShippingLabel(order) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </HCol>
            </HRow>
        </div>

    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import jsZip from 'jszip'
import {
    saveAs
} from 'file-saver'

import Http from '@/http'

import appsettings from '@/appsettings'
import Routes from '@/constants/routes'
import Formats from '@/constants/formats'
import OrderStatus from '@/constants/order-status'
import ProductStatus from '@/constants/product-status'

import {
    getTimestamp,
    getDateFromString,
    formatLocalDate
} from '@/modules/date'
import {
    getMonthLabel,
    getOrderStatusLabel,
    getServiceStatusLabel,
    getProductStatusLabel,
    getProductStatusTextClass,
    getServiceStatusTextClass
} from '@/modules/labels'
import {
    getOrderStatusIcon,
    getServiceStatusIcon,
    getProductStatusIcon
} from '@/modules/icons'
import {
  getProductOptionsAsHtml,
  getServiceOptionsAsHtml,
  getOrderServiceOptionsAsHtml
} from '@/modules/services'

import OrderStatusViewer from '@/components/OrderStatusViewer'
import IncidentSidebar from '@/components/IncidentSidebar'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import BackButton from '@/components/BackButton'
import {
  HBtn,
  HRow,
  HCol
} from '@happytal/bo-ui-library'

const vueAppEhpadApi = appsettings.VUE_APP_EHPAD_API

export default {
    name: 'Order',
    props: [
        'resident_id',
        'order_id'
    ],
    components: {
        OrderStatusViewer,
        IncidentSidebar,
        ResidentHeader,
        BackButton,
        HBtn,
        HRow,
        HCol
    },
    data () {
        return {
            loaded: false,
            order: {},
            incidentSidebarActive: false,
            incidentProducts: [],
            incidentActionType: 'open'
        }
    },
    computed: {
        incidentTitle () {
            return this.incidentActionType == 'open' ? `Déclarer un incident` : `Clôturer un incident`
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getCurrentResident () {
            return this.getResidentById(this.resident_id)
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getProductIncidentLegend (incident) {
            const date = formatLocalDate(incident.openedOnUtc, Formats.LogisticDate)
            const firstName = _.get(incident, 'openedBy.firstName', '-')
            const lastName = _.get(incident, 'openedBy.lastName', '-')
            const name = `${firstName} ${lastName}`
            return `Le ${date} par ${name}`
        },
        onBackBtnClick (e) {
            this.$router.push({
                name: Routes.ResidentHistory,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        isIncidentBtnDisabled () {
            return this.$aclChecker.some(this.$acl, [
                'isEhpadManager',
                'isEhpadEmployee'
            ])
        },
        isIncidentBtnVisible () {
            return this.order.canOpenIncident &&
            this.order.status == 'RECEIVED' &&
            this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily',
                'isEhpadManager',
                'isEhpadEmployee',
                'isHappytalEmployee',
                'isHappytalSupport'
            ])
        },
        showIncidentSideBar (visible) {
            this.incidentSidebarActive = visible
        },
        onIncidentBtnClick () {
            this.showIncidentSideBar(true)
        },
        onIncidentSidebarClose () {
            this.showIncidentSideBar(false)
        },
        onIncidentSidebarConfirm (products) {
            this.showIncidentSideBar(false)
            const incidentProducts = _.filter(products, (product) => {
                return product.reason != "0" && !product.incident
            })
            if (!incidentProducts.length) {
                return
            }
            const incidents = _.map(incidentProducts, (product) => {
                return {
                    orderLineId: product.orderLineId,
                    reasonCode: product.reason
                }
            })
            const orderId = this.order.orderId
            const requestUrl = this.incidentActionType == 'open' ?
            `/order-logistic-products/${orderId}/incidents` :
            `/order-logistic-products/${orderId}/incidents/close`
            Http.apis.ehpad.post(requestUrl, {
                userId: this.getUserId(),
                incidents
            })
            .then((response) => {
                this.updateOrder()
            })
            .catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })
        },
        getOrderStatusIcon (order) {
            return getOrderStatusIcon(order)
        },
        getShippingLabel (order) {
            if (order.shippingSameDay) {
                return `Livraison estimée : ${order.shippingLatestDateLabel}`
            }
            else {
                return `Livraison estimée : entre le ${order.shippingEarliestDateLabel} et
                le ${order.shippingLatestDateLabel}`
            }
        },
        getStatusText (order) {
            return getOrderStatusLabel(order)
        },
        getDateLabel (date) {
            return formatLocalDate(date, Formats.OrderDate)
        },
        getRealOrderId (orderId) {
            const found = orderId.match(/-([0-9]+)-/)
            return found && found.length > 1 ? found[1] : 0
        },
        getCustomerNameFromAddress (address) {
            // Mme Adèle ARTOIS
            const civility = _.get(address, 'civility', '')
            const firstName = _.get(address, 'firstName', '')
            const lastName = _.get(address, 'lastName', '')
            return `${civility} ${firstName} ${lastName}`
        },
        getInstitutionNameFromAddress (address) {
            // Résidence Bois Fleury
            return _.get(address, 'street1', '')
        },
        getText1FromAddress (address) {
            // 1160 rue Passe Debout, 45 770 SARAN
            return _.get(address, 'street2', '')
        },
        getText2FromAddress (address) {
            // 1160 rue Passe Debout, 45 770 SARAN
            var text = ''
            const zipCode = _.get(address, 'zipCode', '')
            if (zipCode) {
                text += zipCode
            }
            const city = _.get(address, 'city', '')
            if (city) {
                text += ` ${city}`
            }
            return text
        },
        getClosedIncidentText (order) {
            const incident = order.lastClosedIncident
            const date = formatLocalDate(incident.closedOnUtc, Formats.LogisticDate)
            //const vendor = _.get(incident, 'closedBy', '-')
            return `Incident clôturé le ${date}`// par ${vendor}`
        },
        onDownloadBtnClick (order) {
            this.zipOrders([order], this.getZipSingleFileName(order))
        },
        zipOrders (orders, zipFileName) {
            var zip = new jsZip()
            _.forEach(orders, (order, index) => {
                const fileName = _.get(order, 'invoice.fileName', '')
                const url = _.get(order, 'invoice.url', '')
                zip.file(`${index}-${fileName}`, this.urlToPromise(url))
            })
            zip.generateAsync({
                type: 'blob'
            })
            .then((blob) => {
                saveAs(blob, zipFileName)
            },
            (err) => {
                this.showErrorToast(err)
            })
        },
        urlToPromise (url) {
            return new Promise((resolve, reject) => {
                Http.apis.ehpad.request({
                        responseType: 'arraybuffer',
                        url,
                        method: 'get'
                    })
                    .then((response) => {
                        const data = response.data || {}
                        resolve(data)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
            })
        },
        getZipResidentName () {
            const resident = this.getCurrentResident()
            return `${resident.firstName}_${resident.lastName}`
        },
        getZipDateLabel (date) {
            return formatLocalDate(date, Formats.InvoiceDate)
        },
        getZipSingleFileName (order) {
            const dateLabel = this.getZipDateLabel(order.date)
            const residentName = this.getZipResidentName()
            const name = `${order.orderId}_${dateLabel}_${residentName}.zip`
            return name.replace(/\s/g, '-')
        },
        getFirstInvoice (salesDocuments) {
            return _.find(salesDocuments, (salesDocument) => {
                return salesDocument.type == 'Invoice'
            })
        },
        getOrderFromApi (data, orderId) {
            var order = {
                amount: 0,
                shippingAmount: 0,
                products: []
            }
            this.incidentProducts = []
            const foundLogisticProduct = _.find(_.get(data, 'order.orderLogisticProducts', []), (logisticProduct) => {
                return logisticProduct.id == orderId
            })
            if (foundLogisticProduct) {
                order.type = 'product'
            }
            const foundLogisticService = _.find(_.get(data, 'order.orderLogisticServices', []), (logisticService) => {
                return logisticService.id == orderId
            })
            if (foundLogisticService) {
                order.type = 'service'
            }
            const logistic = foundLogisticProduct || foundLogisticService
            const incidents = _.get(logistic, 'incidents', [])
            const activeIncidents = _.filter(incidents, (incident) => {
                return !incident.closedOnUtc
            })
            order.hasOpenedIncidents = activeIncidents.length > 0
            var closedIncidents = _.filter(incidents, (incident) => {
                return incident.closedOnUtc
            })
            closedIncidents = _.sortBy(closedIncidents, (incident) => {
                const closedDate = getDateFromString(incident.closedOnUtc)
                return -getTimestamp(closedDate)
            })
            order.hasClosedIncidents = closedIncidents.length > 0
            var allProductHaveIncident = true
            var allProductHaveClosedIncident = true
            order.receivedDateText = null
            var receivedDates = []
            switch (order.type) {
                case 'product':
                    order.sellerName = _.get(logistic, 'orderContent.shopName', '')
                    order.date = _.get(logistic, 'orderContent.createdDate', null)
                    order.status = _.get(logistic, 'orderContent.orderState', '')
                    order.statusClass = getProductStatusTextClass(order.status)
                    order.billingAddress = _.get(logistic, 'orderContent.customer.billingAddress', {})
                    order.shippingAddress = _.get(logistic, 'orderContent.customer.shippingAddress', {})
                    order.shippingAmount = _.get(logistic, 'orderContent.shippingTTC', 0.0)
                    order.amount = _.get(logistic, 'orderContent.totalPriceTTC', 0.0)
                    order.shippingTrackingUrl = _.get(logistic, 'orderContent.shippingTrackingUrl', null)
                    order.shippingTypeLabel = _.get(logistic, 'orderContent.shippingTypeLabel', null)
                    const earliestDateText = _.get(logistic, 'orderContent.deliveryDate.earliest', null)
                    const earliestDate = getDateFromString(earliestDateText)
                    order.shippingEarliestDateLabel = earliestDate.format(Formats.DisplayDate)
                    const latestDateText = _.get(logistic, 'orderContent.deliveryDate.latest', null)
                    const latestDate = getDateFromString(latestDateText)
                    order.shippingLatestDateLabel = latestDate.format(Formats.DisplayDate)
                    order.shippingSameDay = earliestDateText == latestDateText
                    const orderLines = _.get(logistic, 'orderContent.orderLines', [])
                    orderLines.forEach((orderLine) => {
                        const id = _.get(orderLine, 'productSku', 0)
                        const photo = _.get(orderLine, 'productMedias[0].mediaUrl', '')
                        const title = _.get(orderLine, 'productTitle', '')
                        const offer = _.find(data.productOffers, (offer) => {
                            return offer.product.productSku == id
                        })
                        const description = offer ? getProductOptionsAsHtml({
                            ...offer,
                            variantInfo: {
                                data: data.variantData
                            }
                        }) : _.get(orderLine, 'description', '')
                        const quantity = _.get(orderLine, 'quantity', 0)
                        const receivedDate = _.get(orderLine, 'receivedDate', null)
                        const priceTTC = _.get(orderLine, 'totalPriceTTC', 0.0)
                        const shippingTTC = _.get(orderLine, 'shippingTTC', 0.0)
                        const price = (priceTTC - shippingTTC).toFixed(2)
                        const status = _.get(orderLine, 'orderLineState', '')
                        const statusIcon = getProductStatusIcon(status)
                        const statusLabel = getProductStatusLabel(status)
                        const statusClass = getProductStatusTextClass(status)
                        const orderLineId = _.get(orderLine, 'orderLineId', null)
                        const incident = _.find(incidents, (incident) => {
                            return orderLineId == incident.orderLineId && !incident.closedOnUtc
                        })
                        // Search for any product without any incident (opened and closed)
                        if (allProductHaveIncident) {
                            const productIncident = _.find(incidents, (incident) => {
                                return orderLineId == incident.orderLineId
                            })
                            if (!productIncident) {
                                allProductHaveIncident = false
                            }
                        }
                        // Search for any product without any incident (only closed)
                        if (allProductHaveClosedIncident) {
                            const productIncident = _.find(closedIncidents, (incident) => {
                                return orderLineId == incident.orderLineId
                            })
                            if (!productIncident) {
                                allProductHaveClosedIncident = false
                            }
                        }
                        receivedDates.push(getDateFromString(receivedDate))
                        order.products.push({
                            id,
                            photo,
                            title,
                            description,
                            legend: '',
                            statusIcon,
                            statusLabel,
                            statusClass,
                            quantity,
                            price,
                            incident
                        })
                        // Check if product's incident has been closed
                        const closedIncident = _.find(closedIncidents, (incident) => {
                            return orderLineId == incident.orderLineId
                        })
                        if (!closedIncident && status != ProductStatus.REFUSED) {
                            this.incidentProducts.push({
                                id,
                                orderLineId,
                                photo,
                                title,
                                reason: 0,
                                incident
                            })
                        }
                    })
                    break
                case 'service':
                    order.sellerName = _.get(logistic, 'orderContent.shop.name', '')
                    order.date = _.get(logistic, 'orderContent.dateCreated', null)
                    order.status = _.get(logistic, 'orderContent.state', '')
                    order.statusClass = getServiceStatusTextClass(order.status)
                    order.billingAddress = _.get(logistic, 'orderContent.customer.billing_address', {})
                    order.shippingAddress = order.billingAddress
                    const id = _.get(logistic, 'orderContent.service.code', 0)
                    const photo = _.get(logistic, 'orderContent.service.medias[0].url', '')
                    const title = _.get(logistic, 'orderContent.service.title', '')
                    const service = _.get(logistic, 'orderContent.service', {})
                    const serviceModel = _.find(data.services, (service) => {
                        return service.serviceModel.code == id
                    })
                    const options = _.get(logistic, 'orderContent.price.options', [])
                    const description = getOrderServiceOptionsAsHtml(options)
                    const quantity = 1
                    const price = _.get(logistic, 'orderContent.totalPriceTTC', 0.0).toFixed(2)
                    const statusIcon = getServiceStatusIcon(order.status)
                    const statusLabel = getServiceStatusLabel(order.status)
                    const statusClass = getServiceStatusTextClass(order.status)
                    order.amount = price
                    order.products.push({
                        id,
                        photo,
                        title,
                        description,
                        legend: '',
                        statusIcon,
                        statusLabel,
                        statusClass,
                        quantity,
                        price
                    })
                    this.incidentProducts.push({
                        id,
                        photo,
                        title,
                        reason: 0
                    })
                    break
            }
            receivedDates = _.sortBy(receivedDates, (date) => {
                return -getTimestamp(date)
            })
            const lastReceivedDate = _.get(receivedDates, '[0]', null)
            order.receivedDateText = lastReceivedDate ? formatLocalDate(lastReceivedDate, Formats.LogisticDate) : null
            order.orderId = _.get(logistic, 'id', 0)
            const salesDocuments = _.get(logistic, 'salesDocuments', [])
            const invoice = this.getFirstInvoice(salesDocuments)
            const fileId = _.get(invoice, 'fileId', null)
            const fileName = _.get(invoice, 'fileName', '')
            const invoiceUrl = fileId ? `${vueAppEhpadApi}/files/${fileId}?download=true` : null
            order.hasInvoices = invoiceUrl != null
            if (order.hasInvoices) {
                order.invoice = {
                    fileName,
                    url: invoiceUrl
                }
            }
            order.incidents = incidents
            order.lastClosedIncident = allProductHaveClosedIncident ? closedIncidents[0] : null
            order.canOpenIncident = this.incidentProducts.length > 0 && order.status != OrderStatus.CLOSED && !allProductHaveIncident
            order.paymentType = _.get(data, 'order.metadata.order_type', '')
            return order
        },
        updateOrder () {
            this.loaded = false
            const realOrderId = this.getRealOrderId(this.order_id)
            this.$store.dispatch('dataOrders/getOrderById', {
                orderId: realOrderId
            })
            .then((apiOrder) => {
                this.order = this.getOrderFromApi(apiOrder, this.order_id)
                //console.log('order', this.order)
                this.loaded = true
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        })
                        .then(() => {

                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                this.updateOrder()
            }
        )
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.incident-menu {
    width: 292px;
    z-index: 55000;

    .incident-content {
        padding: 25px 25px 25px 25px;
        color: #2C2C2C;

        .incident-header {
            display: flex;
            align-items: center;

            .alert-icon {

            }
            .incident-title {
                flex-grow: 1;
                margin: 0px 0px 0px 10px;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .incident-reason {
            margin: 5px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #FFA253;
        }
        .incident-text {
            margin: 10px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.status-label-ok {
    color: rgba(var(--vs-primary), 1);
}
.status-label-ko {
    color: #E03A3E;
}

.incident-dropdown {
    flex-shrink: 0;
    cursor: pointer;

    .alert-btn {
        margin: 0px 23px 0px 0px;
    }
    .alert-icon {
        width: 18px
    }
}

.help-menu {
    width: 292px;

    .help-content {
        padding: 25px 25px 25px 25px;
        color: #2C2C2C;

        .help-header {
            display: flex;
            align-items: center;

            .alert-icon {

            }
            .help-title {
                flex-grow: 1;
                margin: 0px 0px 0px 10px;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .help-text {
            margin: 10px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

#resident-order {
    color: #2C2C2C;

    .help-dropdown {
        cursor: pointer;

        .help-btn {
            margin: 0px 0px 0px 23px;
        }
        .alert-icon {

        }
    }
    .incident-dropdown {
        cursor: pointer;

        .alert-btn {
            margin: 0px 23px 0px 0px;
        }
        .alert-icon {

        }
    }

    .status-bar {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 12px 20px 12px 20px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
        border-radius: 5px;

        @media (max-width: map-get($breakpoints, sm)) {
            .status-label {
                width: 100%;
            }
            .vendor-label {
                width: 100%;
                margin: 10px 0px 0px 0px !important;
            }
            .status-blank {
                display: none;
            }
            .tracking-btn {
                width: 100%;
                margin: 10px 0px 0px 0px !important;
            }
            .incident-btn {
                width: 100%;
                margin: 10px 0px 0px 0px !important;
            }
            .invoice-btn {
                width: 100%;
                margin: 10px 0px 0px 0px !important;
            }
        }

        .status-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-transform: uppercase;
        }
        .vendor-label {
            margin: 0px 0px 0px 20px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #606060;
        }
        .status-blank {
            flex-grow: 1;
        }
        .tracking-btn {
            margin: 0px 0px 0px 12px;
        }
        .incident-btn {

        }
        .invoice-btn {
            margin: 0px 0px 0px 12px;
        }
    }

    .order {
        color: #2C2C2C;

        .order-header {
            display: flex;
            align-items: center;
            margin: 21px 0px 21px 0px;
            color: black;

            @media (max-width: map-get($breakpoints, sm)) {
                margin: 0px 10px 0px 10px;
            }

            .number {
                flex-grow: 1;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
            }
            .date {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;

                @media (max-width: map-get($breakpoints, sm)) {
                    text-align: right;
                }
            }
        }
        .order-content {
            margin-top: 20px;

            .order-list {
                //margin: 0px 0px 21px 0px;

                .order-list-header {
                    padding: 8px 14px 9px 14px;
                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
                    border-radius: 5px 5px 0px 0px;
                    background: #F9F9F9;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: black;
                }
                .products {
                    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);

                    .product-item {
                        display: flex;
                        align-items: center;
                        padding: 24px 27px 24px 27px;
                        border-bottom: 1px solid #DDDDDD;
                        background: white;

                        @media (max-width: map-get($breakpoints, sm)) {
                            padding: 24px 5px 24px 5px;
                        }

                        .product-photo {
                            width: 56px;
                            height: 56px;
                            background: #A5A5A5;

                            @media (max-width: map-get($breakpoints, sm)) {
                                width: 35px;
                                height: 35px;
                            }
                        }
                        .informations {
                            flex-grow: 1;
                            margin: 0px 0px 0px 16px;
                            color: black;

                            .product-title {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 14px;
                            }
                            .product-description {
                                margin: 10px 0px 0px 0px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                            }
                            .product-legend {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                        .status {
                            flex-shrink: 0;
                            width: 150px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            text-align: center;

                            @media (max-width: map-get($breakpoints, sm)) {
                                width: 100px;
                                font-size: 11px;
                                line-height: 10px;
                            }
                        }
                        .quantity {
                            flex-shrink: 0;
                            width: 150px;
                            text-align: center;

                            @media (max-width: map-get($breakpoints, sm)) {
                                width: 50px;
                            }

                            .item-bullet {
                                width: 60px;
                                height: 24px;
                                margin: 0px auto 0px auto;
                                padding: 4px 20px 4px 20px;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 17px;
                                background: #BDBDBD;
                                border-radius: 3px;
                                text-align: center;
                                color: white;

                                @media (max-width: map-get($breakpoints, sm)) {
                                    width: 30px;
                                    height: 24px;
                                    padding: 4px 5px 4px 5px;
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                            }
                        }
                        .price {
                            flex-shrink: 0;
                            width: 100px;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 16px;
                            text-align: center;
                            color: rgba(var(--vs-primary), 1);

                            @media (max-width: map-get($breakpoints, sm)) {
                                width: 50px;
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }
                    }
                }
                .footer {
                    padding: 10px 20px 10px 20px;
                    background: #F9F9F9;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
                    border-radius: 0px 0px 5px 5px;
                    color: black;

                    .shipping {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        text-align: right;
                    }
                    .total {
                        margin: 10px 0px 0px 0px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 14px;
                        text-align: right;
                    }
                }
            }
            .information-list {
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
                border-radius: 5px 5px 5px 5px;
                background: #F9F9F9;

                .information-list-header {
                    padding: 8px 14px 9px 14px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 14px;
                    color: black;
                }
                .informations {
                    color: black;

                    .information-item {
                        padding: 14px 21px 23px 21px;
                        border-bottom: 1px solid #DDDDDD;
                        background: white;

                        .title {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                        }
                        .resident-icon {
                            width: 12px;
                        }
                        .text {
                            margin: 7px 0px 0px 0px;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                    .information-item:last-child {
                        border-radius: 0px 0px 5px 5px;
                    }
                }
            }
        }
    }
}
</style>
