<template>
    <vs-sidebar
        class="incident-sidebar"
        click-not-close
        position-right
        parent="body"
        default-index="1"
        color="primary"
        spacer
        v-model="active"
    >
        <div class="sidebar-header">
            <div class="sidebar-title">{{ title }}</div>
            <div
                class="close-btn"
                @click="onCloseBtnClick"
            >
                <img class="close-icon" src="@/assets/images/ehpad/x.svg" />
            </div>
        </div>
        <div class="sidebar-products">
            <div
                class="sidebar-product"
                v-for="(product, productIndex) in products"
                :key="productIndex"
            >
                <img
                    class="product-photo"
                    :src="product.photo"
                />
                <div class="product-informations">
                    <div class="product-title">{{ product.title }}</div>
                    <vs-select
                        class="reason-select"
                        autocomplete
                        v-model="product.reason"
                        v-if="!product.incident"
                    >
                        <vs-select-item
                            :value="reason.value"
                            :text="reason.text"
                            v-for="(reason, reasonIndex) in reasons"
                            :key="reasonIndex"
                        />
                    </vs-select>
                    <div
                        class="reason-text"
                        v-else
                    >{{ getReasonText(product.incident) }}</div>
                </div>
            </div>
        </div>
        <div class="sidebar-actions">
            <vs-button
                class="cancel-btn"
                type="border"
                @click="onCancelBtnClick"
            >Annuler</vs-button>
            <vs-button
                class="confirm-btn"
                @click="onConfirmBtnClick"
            >Confirmer</vs-button>
        </div>
    </vs-sidebar>
</template>

<script>
import _ from 'lodash'

import Http from '@/http'

export default {
    name: 'IncidentSidebar',
    props: {
        active: {
            type: Boolean,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            settings: {
                // perfectscrollbar settings
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },
            reasons: []
        }
    },
    methods: {
        onCloseBtnClick (e) {
            this.$emit('close')
        },
        onCancelBtnClick (e) {
            this.$emit('close')
        },
        onConfirmBtnClick (e) {
            this.$emit('confirm', this.products)
        },
        getReasonText (incident) {
            return incident.label
        },
        getReasons () {
            const types = this.type == 'open' ? 'INCIDENT_OPEN' : 'INCIDENT_CLOSE'
            Http.apis.ehpad.get(`/reasons?types=${types}`).then((response) => {
                const data = response.data || {}
                this.reasons = this.type == 'open' ? [
                    { value: "0", text: `Pas d’incident`, displayOrder: -1 }
                ] : []
                const reasons = data.reasons || []
                reasons.forEach((reason) => {
                    this.reasons.push({
                        value: reason.code,
                        text: reason.label,
                        displayOrder: reason.displayOrder
                    })
                })
                this.reasons = _.sortBy(this.reasons, (reason) => {
                    return reason.label
                })
            })
            /*.catch((error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            })*/
        }
    },
    mounted () {
        this.getReasons()
    }
}
</script>

<style lang="scss" scoped>
.incident-sidebar {
    color: #2C2C2C;

    .sidebar-header {
        display: flex;
        align-items: center;
        padding: 5px 15px 15px 15px;
        border-bottom: 1px solid #D8D8D8;

        .sidebar-title {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
        }
        .close-btn {
            cursor: pointer;

            .close-icon {
                width: 12px;
                height: 12px;
            }
        }
    }
    .sidebar-products {
        height: calc(100% - 120px);
        overflow-y: auto;

        .sidebar-product {
            display: flex;
            padding: 16px 16px 16px 16px;
            border-bottom: 1px solid #D8D8D8;

            .product-photo {
                width: 93px;
                height: 93px;
                background: #A5A5A5;
            }
            .product-informations {
                flex-grow: 1;
                margin: 0px 0px 0px 15px;

                .product-title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    color: #2C2C2C;
                }
                .reason-select {
                    margin: 10px 0px 0px 0px;
                }
                .reason-text {
                    margin: 20px 0px 0px 0px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #FFA253;
                }
            }
        }
    }
    .sidebar-actions {
        display: flex;
        align-items: center;
        padding: 15px 15px 15px 15px;

        .cancel-btn {
            flex-grow: 1;
            margin: 0px 15px 0px 0px;
        }
        .confirm-btn {
            flex-grow: 1;
        }
    }
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    ::v-deep .vs-sidebar {
        z-index: 52010;
        width: 400px;
        max-width: 90vw;
    }
}
.scroll-area {
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
        overflow-y: auto;
    }
}
</style>
